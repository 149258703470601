<template>
  <div class="my-orders">
    <template v-if="!offerLoading && lists.length != 0">
      <div
        v-for="list in lists"
        :key="list.order_id"
      >
        <BaseOrderCard
          v-for="(item, index) in list.purchases"
          :key="index"
          :purchase="item"
          :orderId="list.order_id"
          @goToOffer="setOfferId"
        />
      </div>
    </template>

    <template v-if="offerLoading">
      <listOrderLoader
        v-for="item in 3"
        :key="item"
      />
    </template>

    <template v-else-if="lists.length == 0 && !offerLoading">
      <h2 class="text-center mb-5">
        {{ errorMessage.html }}
      </h2>
      <div class="text-center">
        <img
          :src="errorMessage.image"
          alt=""
        >
      </div>
    </template>
    <pagination
      v-if="lists.length"
      v-model="page"
      :options="{ chunk: 3 }"
      :records="paginationNumberPages"
      :per-page="10"
      @paginate="goToCurrentIndex"
    />
  </div>
</template>

<script>
import GetProfileData from '../../../../services/profile'
import Pagination from 'v-pagination-3'
import listOrderLoader from '../../../partials/base-order-card/baseOrderLoader.vue'

export default {
  name: 'MyOrders',
  components: {
    Pagination,
    listOrderLoader
  },

  data () {
    return {
      errorMessage: {
        image: '',
        html: ''
      },
      lists: [],
      page: 1,
      offerLoading: true,
      paginationNumberPages: 0
    }
  },
  computed: {
    userData () {
      return this.$store.getters['Globals/userInfo']
    }
  },
  created () {
    this.getAllLists()
  },
  methods: {
    setOfferId (id, name) {
      const offerName = name.replace(/[ "" 25%]/g, '-')
      const path = {
        name: 'offer-details',
        params: { id, offerName }
      }
      if (this.$route.path !== path) this.$router.push(path)
    },

    goToCurrentIndex (page) {
      this.page = page
      this.offerLoading = true
      this.lists = []
      this.getAllLists()
    },

    getAllLists () {
      const payload = {
        app_version: '',
        store: '',
        token: this.getUserToken,
        page: this.page
      }
      this.offerLoading = true
      GetProfileData.GetListPurchases(payload)
        .then((res) => {
          this.paginationNumberPages = res.data.count
          this.offerLoading = false
          this.lists = res.data.orders
        })
        .catch((err) => {
          this.errorMessage.html = err.response.data.data.message
          this.errorMessage.image = err.response.data.data.image
          this.offerLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
li.VuePagination__pagination-item:not(.VuePagination__pagination-item-prev-page):not(.VuePagination__pagination-item-prev-chunk):not(.VuePagination__pagination-item-next-page):not(.VuePagination__pagination-item-next-chunk) {
  display: none;
}
</style>
