<template>
  <div class="w-100">
    <MyOrders />
  </div>
</template>

<script>
import MyOrders from '@/components/profile/orders/my-orders/Index.vue'

export default {
  name: 'MyOrdersPage',
  components: { MyOrders }
}
</script>
