<template>
  <div class="base-how-to-use-coupon black-color-bg p-t-20 radius-14">
    <img
      src="./how-to-use-coupon.svg"
      alt=""
    >
    <div class="p-x-14 p-y-20">
      <h3 class="bold-font fs-18 white-color-text m-b-20">
        {{ $t('account.howToUseCoupon') }}
      </h3>
      <p class="fs-14 light-gray-color-text m-b-16">
        {{ $t('account.orderStep1') }}
      </p>
      <p class="fs-14 light-gray-color-text m-b-16">
        {{ $t('account.orderStep2') }}
      </p>
      <p class="fs-14 light-gray-color-text m-b-16">
        {{ $t('account.orderStep3') }}
      </p>
    </div>

    <div class="purchase-coupons-box p-x-14 p-t-40 p-b-20 white-color-bg">
      <h3 class="bold-font fs-18 black-color-text m-b-20">
        {{ $t('account.purchaseCoupons') }}
      </h3>
      <p class="fs-14 light-gray-color-text m-b-16">
        {{ $t('account.orderStep1') }}
      </p>
      <p class="fs-14 light-gray-color-text m-b-16">
        {{ $t('account.orderStep2') }}
      </p>
      <p class="fs-14 light-gray-color-text m-b-16">
        {{ $t('account.orderStep3') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseHowToUseCoupon'
}
</script>

<style lang="scss" scoped>
.base-how-to-use-coupon {
  box-shadow: 1px 3px 11px 3px rgba($black-color, 0.1);
  overflow: hidden;
  position: relative;
  @media (max-width: 992px) {
    margin-top: 50px;
  }

  img {
    position: absolute;
    right: 0;
    top: 50%;
    width: 150px;

    [dir='rtl'] & {
      right: auto;
      left: 0;
    }
  }

  .purchase-coupons-box {
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    h3,
    p {
      position: relative;
    }
  }
}
</style>
