<template>
  <div class="w-100 orders">
    <div class="row">
      <div class="col-12 col-lg-8">
        <BaseTabs :routes="routes" />
        <div class="content white-color-bg p-y-22 p-x-18 radius-14">
          <router-view />
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="how-to-use-coupons">
          <BaseHowToUseCoupon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHowToUseCoupon from '@/components/partials/base-how-to-use-coupon/Index.vue'

export default {
  name: 'Orders',
  components: { BaseHowToUseCoupon },
  data () {
    return {
      routes: [
        {
          to: { name: 'my-orders' },
          title: 'account.allOrders'
        },

        {
          to: { name: 'favorite-offers' },
          title: 'account.fav'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.orders {
  .content {
    box-shadow: 1px 3px 11px 3px rgba($black-color, 0.1);
  }
}
</style>
